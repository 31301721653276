<template>
  <div class="container page">
    <div class="header">
      <div class="nav-bar van-nav-bar van-hairline--bottom">
        <div class="van-nav-bar__content">
          <div class="van-nav-bar__left">
            <div class="van-image" style="width: 32px;">
              <img src="img/home.png"  @click="home" class="van-image__img"></div>
          </div>
          <div class="van-nav-bar__title van-ellipsis"></div>
          <div class="van-nav-bar__right">
            <div class="van-image" style="width: 32px;"><img src="img/kefu.png"  @click="toService"
                                                             class="van-image__img"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ifrmae_page">
      <iframe width="100%" height="80vh" frameborder="0" id="iframe_web"
              :src="$store.getters.getBaseInfo.external_url" style="height: 725px;"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "JumpPage",
  data() {
    return {
      url: "",
    }
  },
  mounted(){
    var t = document.getElementById("iframe_web")
        , e = document.documentElement.clientHeight;
    t.style.height = Number(e) - 65 + "px"
  },
  methods: {
    toService(){
      this.$router.push("ServiceOnline");
    },
    home(){
      this.$router.push({path: '/Home'});
    }


  }
}

</script>

<style scoped>

</style>